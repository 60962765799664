<template>
    <div class="qingwu">
        <div class="admin_main_block">
            <div class="admin_main_block_top">
                <div class="admin_main_block_left">
                    <div>线下订单编辑</div>
                </div>

                <div class="admin_main_block_right">
                    <div>
                        <el-button icon="el-icon-back" @click="$router.go(-1)">返回</el-button>
                    </div>
                </div>
            </div>

            <div class="admin_form_main">
                <el-form label-width="100px" ref="info" :model="info">

                    <el-form-item label="姓名" prop="xingm" :rules="[{required:true,message:'姓名不能为空',trigger: 'blur' }]">
                        <el-input placeholder="请输入姓名" v-model="info.xingm"></el-input>
                    </el-form-item>
                    <el-form-item label="手机号码" prop="shoujh" :rules="[{required:true,message:'手机号码不能为空',trigger: 'blur' }]">
                        <el-input placeholder="请输入手机号码" v-model="info.shoujh"></el-input>
                    </el-form-item>
                    <el-form-item label="性别" prop="xingb">
                        <el-select v-model="info.xingb" placeholder="选择性别">
                            <el-option label="男" value="男"></el-option>
                            <el-option label="女" value="女"></el-option>
                        </el-select>
                    </el-form-item>


                    <el-form-item label="门店地址" prop="menddz">
                        <el-select v-model="info.menddz" placeholder="请选择门店地址" @change="selectChange()">
                            <el-option label="请选择门店地址" :value="0"></el-option>
                            <el-option v-for="(v,k) in info['mendlist']" :label="v.F_VR_MINGC" :key="k" :value="v.F_VR_MINGC"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="服务老师" prop="fuwls">
                        <el-select v-model="info.fuwls" placeholder="请选择服务老师" @change="selectChange()">
                            <el-option label="请选择服务老师" :value="0"></el-option>
                            <el-option v-for="(v,k) in info['zsls']" :label="v.F_VR_XINGM" :key="k" :value="v.F_VR_XINGM"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="消费套餐" prop="xiaoftc" :rules="[{required:true,message:'消费套餐不能为空',trigger: 'blur' }]">
                        <el-input placeholder="请输入消费套餐" v-model="info.xiaoftc"></el-input>
                    </el-form-item>
                    <el-form-item label="实际付款金额" prop="shijfkje" :rules="[{required:true,message:'实际付款金额不能为空',trigger: 'blur' }]">
                        <el-input placeholder="请输入实际付款金额" v-model="info.shijfkje"></el-input>
                    </el-form-item>
                    <el-form-item label="配色" prop="peis" :rules="[{required:true,message:'配色不能为空',trigger: 'blur' }]">
                        <el-input placeholder="请输入配色" v-model="info.peis"></el-input>
                    </el-form-item>

                    <el-form-item label="顾客来源" prop="xiaofly">
                        <el-select v-model="info.xiaofly" placeholder="请选择顾客来源" @change="selectChange()">
                            <el-option label="请选择顾客来源" :value="0"></el-option>
                            <el-option v-for="(v,k) in info['xianxddxfly']" :label="v.mingc" :key="k" :value="v.mingc"></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="支付方式" prop="zhiffs">
                        <el-select v-model="info.zhiffs" placeholder="请选择支付方式" @change="selectChange()">
                            <el-option label="请选择支付方式" :value="0"></el-option>
                            <el-option v-for="(v,k) in info['xianxddxffs']" :label="v.mingc" :key="k" :value="v.mingc"></el-option>
                        </el-select>
                    </el-form-item>


                    <el-form-item label="备注" prop="beiz">
                        <el-input type="textarea" placeholder="请输入备注" maxlength="200" show-word-limit
                                  v-model="info.beiz"></el-input>
                    </el-form-item>




                    <el-form-item>
                        <el-button type="primary" @click="submitForm('info')">提交</el-button>
                        <!-- <el-button @click="resetForm('info')">重置</el-button> -->
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        components: {},
        props: {},
        data() {
            return {
                edit_id: 0,
                info: {},
                list: [],
                upload_headers: {},
            };
        },
        watch: {},
        computed: {},
        methods: {
            resetForm: function (e) {
                this.$refs[e].resetFields();
            },
            submitForm: function (e) {
                let _this = this;

                // 验证表单
                this.$refs[e].validate(function (res) {
                    if (res) {
                        //  判断是Add 或者 Edit
                        let url = _this.$api.addZsls;
                        if (_this.edit_id > 0) {
                            url = _this.$api.editXianxdd + _this.edit_id;
                        }

                        // Http 请求
                        _this.$post(url, _this.info).then(function (res) {
                            if (res.code == 200) {
                                _this.$message.success("编辑成功");
                                _this.$router.go(-1);
                            } else {
                                _this.$message.error("编辑失败");
                            }
                        });
                    }
                });
            },
            get_adv_info: function () {
                let _this = this;
                this.$get(this.$api.editXianxdd + this.edit_id).then(function (res) {
                    _this.info = res.data;
                    console.log(res.data)
                    _this.list = res.data.gzhgz;
                })

            },
            get_adv_list: function () {
                let _this = this;
                this.$get(this.$api.addZsls).then(function (res) {
                    _this.list = res.data;
                })

            },
            selectChange: function () {
                this.$forceUpdate();
            },
            handleAvatarSuccess(res) {
                this.info.F_VR_TOUX = res.data;
                this.$forceUpdate();
            },
            handleAvatarSuccesses(res) {
                this.info.F_VR_WEIXEWM = res.data;
                this.$forceUpdate();
            },
        },
        created() {
            this.upload_headers.Authorization = 'Bearer ' + localStorage.getItem('token'); // 要保证取到

            if (!this.$isEmpty(this.$route.params.adv_position_id)) {
                this.info.ap_id = this.$route.params.adv_position_id;
            }

            // 判断是否是编辑
            if (!this.$isEmpty(this.$route.params.id)) {
                this.edit_id = this.$route.params.id;
            }

            if (this.edit_id > 0) {
                this.get_adv_info();
            }/*else{
               this.get_adv_list();
            }*/

        },
        mounted() {
        }
    };
</script>
<style lang="scss" scoped>
    .line {
        text-align: center
    }
</style>